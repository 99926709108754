var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-start",attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4',"flat":"","tile":""}},[_c('v-row',[_c('v-col',[_c('v-datetime-picker',{attrs:{"label":"From Date","outlined":""},on:{"input":_vm.getStocksLocal},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}})],1),_c('v-col',[_c('v-datetime-picker',{attrs:{"label":"To Date","outlined":""},on:{"input":_vm.getStocksLocal},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})],1),_c('div',{staticStyle:{"width":"60%"}})],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Stocks by Date"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by.sync":"order_status","sort-desc.sync":"true","search":_vm.search,"items-per-page":50,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.order_status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.color},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.orderStatusToText(item.order_status))+" ")])])]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.deadline)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.deadline))+" ")])])]}},{key:"item.order_created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.order_created_at)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.order_created_at))+" ")])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"60%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"color":"red lighten-2","dark":""}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(_vm._s(_vm.translate("Order"))+" #"+_vm._s(item.id))]),_c('v-card-text',[_c('OrderInfo',{attrs:{"currency":_vm.currency,"order":item}})],1),_c('v-divider')],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'edit_order', query: { id: item.id } }}},[_c('v-icon',{staticStyle:{"color":"var(--v-warning-base)"},attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"var(--v-error-base)"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();item.delete_dialog = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.translate("Are you sure want to delete Order"))+" "+_vm._s(item.id)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_vm._v(_vm._s(_vm.translate("OK")))]),_c('v-spacer')],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }