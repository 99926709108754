<template>
  <div>
    <div
      class="d-flex justify-start"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
      flat
      tile
    >
      <v-row>
        <v-col>
          <v-datetime-picker
            label="From Date"
            outlined
            v-model="from_date"
            @input="getStocksLocal"
          >
          </v-datetime-picker
        ></v-col>
        <v-col>
          <v-datetime-picker
            label="To Date"
            outlined
            v-model="to_date"
            @input="getStocksLocal"
          >
          </v-datetime-picker>
        </v-col>
        <div style="width: 60%"></div>
      </v-row>
    </div>
    <v-card>
      <v-card-title>
        {{ translate("Stocks by Date") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        sort-by.sync="order_status"
        sort-desc.sync="true"
        :search="search"
        :items-per-page="50"
        :headers="headers"
        :items="items"
        class="elevation-1"
      >
        <template v-slot:item.order_status="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"
            ><span class="v-chip__content">
              {{ orderStatusToText(item.order_status) }}
            </span></span
          >
        </template>
        <template v-slot:item.deadline="{ item }">
          <span>{{ formatDate(item.deadline) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content"> {{ timeAgo(item.deadline) }} </span></span
          >
        </template>
        <template v-slot:item.order_created_at="{ item }">
          <span>{{ formatDate(item.order_created_at) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content">
              {{ timeAgo(item.order_created_at) }}
            </span></span
          >
        </template>
        <template v-slot:item.id="{ item }">
          <v-dialog width="60%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                {{ item.id }}
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                >{{ translate("Order") }} #{{ item.id }}</v-card-title
              >
              <v-card-text>
                <OrderInfo :currency="currency" :order="item"></OrderInfo>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'edit_order', query: { id: item.id } }">
            <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
          </router-link>
          <v-dialog :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="item.delete_dialog = true"
                small
                style="color: var(--v-error-base)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5"
                >{{ translate("Are you sure want to delete Order") }}
                {{ item.id }}?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                  translate("Cancel")
                }}</v-btn>
                <v-btn color="primary" @click="handleDelete(item.id)">{{
                  translate("OK")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import OrderInfo from "@/views/pages/orders/OrderInfo.vue";
import {
  deleteOrder,
  getApprovedDrivers,
  getCompletedOrders,
  getCurrency,
  getMagazines,
  getOrders,
  getStocksByDate,
} from "@/ApiManager";
import moment from "moment";

export default {
  computed: {},

  data() {
    return {
      from_date: null,
      to_date: null,
      filteredOrders: [],
      selectedDate: null,
      filterDialog: false,
      search: "",
      selected_order_status: null,
      delete_dialog: false,
      selected_driver: null,
      currency: "",
      magazine_names: [],
      single_name: [],
      drivers: [],
      items: [],
      headers: [
        { text: this.translate("Order Number"), value: "id" },
        { text: this.translate("Product Name"), value: "name" },
        { text: this.translate("Product Quantity"), value: "amount" },

        { text: this.translate("Created At"), value: "order_created_at" },
        { text: this.translate("Deadline"), value: "deadline" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Order Status"), value: "order_status" },
        { text: this.translate("Actions"), value: "actions" },
      ],
    };
  },
  components: {
    OrderInfo,
  },
  methods: {
    getStocksLocal() {
      if ([this.from_date, this.to_date].includes(null)) return;

      var to_date = `${this.to_date.getFullYear()}/${(
        "0" +
        (this.to_date.getMonth() + 1)
      ).slice(-2)}/${("0" + this.to_date.getDate()).slice(
        -2
      )} ${this.to_date.getHours()}:${this.to_date.getMinutes()}:${this.to_date.getSeconds()}`;

      var from_date = `${this.from_date.getFullYear()}/${(
        "0" +
        (this.from_date.getMonth() + 1)
      ).slice(-2)}/${("0" + this.from_date.getDate()).slice(
        -2
      )} ${this.from_date.getHours()}:${this.from_date.getMinutes()}:${this.from_date.getSeconds()}`;

      getStocksByDate(from_date, to_date).then((response) => {
        this.items = response.data;
        console.log(response.data);
        this.items.forEach((product) => {
          product.total_price = `${product.total_price} ${this.currency}`;
          product.color = this.orderStatusToColor(product.order_status);
          product.order_status_formatted = this.orderStatusToText(product.order_status);
        });
        this.$store.state.loading = false;
      });
    },
  },
  created() {
    this.$store.state.loading = true;
    //this.$store.state.socketio.on('orders_updated', (...args) => {
    //  this.getOrdersLocal()
    //})

    getCurrency().then((response) => {
      this.currency = response["currency"];
    });

    getMagazines().then((response) => {
      this.magazine_names = response;
    });
    getApprovedDrivers().then((response) => {
      this.drivers = response;
    });
    this.$store.state.loading = false;
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
